import { find, get, isEmpty, sum, sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useForm } from 'react-hook-form';
import { FaExclamationCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  InputText,
  ReloadButton,
  SubmitButton,
} from '../../components/common';
import { paymentActions, paymentReferenceActions } from '../../config/actions';
import { getInvoiceAmount } from './helper';
import UnPaidInvoices from './UnPaidInvoices';

function GeneratePaymentReference({ navigateUser, activeTab }) {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm();
  const [invoiceType, setInvoiceType] = useState('future-payments');
  const [totalInvoiceDue, setTotalInvoiceDue] = useState(0);
  const [allUnPaidInvoices, setAllUnPaidInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { invoices, loading } = useSelector((state) => state.myInvoice);
  const { generatingPaymentReference } = useSelector(
    (state) => state.paymentReference
  );

  const getInvoices = () => dispatch(paymentActions.getMyInvoices());

  const invoiceTypes = [
    {
      title: 'TO MAKE FULL PAYMENT',
      url: 'all-unpaid-invoices',
      hidden: isEmpty(allUnPaidInvoices),
    },
    {
      title: 'TO MAKE PARTIAL PAYMENT',
      url: 'select-unpaid-invoices',
      hidden: isEmpty(allUnPaidInvoices),
    },
    {
      title: 'FOR FUTURE USE',
      url: 'future-payments',
      hidden: false,
    },
  ];

  useEffect(() => {
    if (isEmpty(invoices)) getInvoices();
  }, []);

  useEffect(() => {
    const tab = find(invoiceTypes, (type) => type.url === invoiceType);

    const activeInvoiceTab = !isEmpty(allUnPaidInvoices)
      ? tab?.title
      : 'To make full payment';

    if (!isEmpty(activeTab)) navigateUser('Generate New PRN', activeInvoiceTab);
  }, [activeTab]);

  useEffect(() => {
    if (!isEmpty(invoices)) {
      let invoiceAmountsDue = 0;
      let unPaidInvoices = [];

      invoices.forEach((invoice) => {
        const tuitionDue = sumBy(invoice.tuition_invoices, 'amount_due');
        const functionalDue = sumBy(
          invoice.functional_fees_invoices,
          'amount_due'
        );
        const otherFeesDue = sumBy(invoice.other_fees_invoices, 'amount_due');
        const manualsDue = sumBy(invoice.manual_invoices, 'amount_due');
        invoiceAmountsDue += sum([
          tuitionDue,
          functionalDue,
          otherFeesDue,
          manualsDue,
        ]);

        const unPaidTuition = invoice?.tuition_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        const unPaidFunctional = invoice?.functional_fees_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        const unPaidManual = invoice?.manual_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        const unPaidOther = invoice?.other_fees_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        unPaidInvoices = [
          ...unPaidInvoices,
          ...unPaidTuition,
          ...unPaidFunctional,
          ...unPaidManual,
          ...unPaidOther,
        ];
      });
      if (!isEmpty(unPaidInvoices)) setInvoiceType('all-unpaid-invoices');

      setTotalInvoiceDue(invoiceAmountsDue);
      setAllUnPaidInvoices(unPaidInvoices);
      setSelectedRows(
        unPaidInvoices.map((unpaidInvoice) => ({
          id: unpaidInvoice?.id,
          invoice_number: unpaidInvoice.invoice_number,
          amount: unpaidInvoice.amount_due,
        }))
      );
    }
  }, [invoices]);

  const handleFormSubmit = (data) => {
    let payload = data;
    let url = 'future';
    if (invoiceType === 'all-unpaid-invoices') url = 'all';
    else if (invoiceType === 'select-unpaid-invoices') {
      url = 'bulk';
      payload = {
        ...payload,
        tuition_invoices: getInvoiceAmount(
          selectedRows,
          'T',
          'tuition_invoice_id'
        ),
        functional_fees_invoices: getInvoiceAmount(
          selectedRows,
          'F',
          'functional_fees_invoice_id'
        ),
        other_fees_invoices: getInvoiceAmount(
          selectedRows,
          'O',
          'functional_fees_invoice_id'
        ),
        manual_invoices: getInvoiceAmount(
          selectedRows,
          'M',
          'manual_invoice_id'
        ),
      };
    }
    dispatch(paymentReferenceActions.generatePaymentReference(payload, url));
  };

  const handleClick = (invoice) => {
    setInvoiceType(invoice?.url);
    navigateUser('Generate New PRN', invoice?.title);
  };
  return (
    <>
      {isEmpty(allUnPaidInvoices) && (
        <AlertMessage
          message="You Have No Unpaid Invoices"
          icon={<FaExclamationCircle className="me-1" />}
          className="font600 text-sm p-2"
          type="info"
          extras={
            <ReloadButton
              loading={loading}
              onClick={getInvoices}
              variant="link"
              text="Reload"
              size="sm"
            />
          }
        />
      )}
      <Alert variant="info" className="fw-bold">
        SELECT PAYMENT TYPE AND GENERATE PRN
      </Alert>

      <Row className="g-1 row-deck">
        <Col md={3}>
          <Card>
            <ListGroup variant="flush" defaultActiveKey={invoiceType}>
              {invoiceTypes.map((invoice, index) => (
                <Fragment key={invoice.url}>
                  {invoice.hidden === false && (
                    <ListGroup.Item
                      active={invoiceType === invoice.url}
                      action
                      onClick={() => handleClick(invoice)}
                      className="fw-bold py-3"
                      style={{ cursor: 'pointer' }}
                    >
                      {`${index + 1}. ${invoice.title}`}
                    </ListGroup.Item>
                  )}
                </Fragment>
              ))}
            </ListGroup>
          </Card>
        </Col>
        <Col md={9}>
          <Card className="bg-white">
            <Card.Header className="fw-bold bg-light py-3 border-light d-block text-primary text-center">
              <span className="me-1">GENERATE PRN TO</span>
              {(invoiceType === 'select-unpaid-invoices' &&
                'MAKE PARTIAL PAYMENT') ||
                (invoiceType === 'all-unpaid-invoices' &&
                  'MAKE FULL PAYMENT') ||
                'DEPOSIT FOR FUTURE USE'}
            </Card.Header>
            <Card.Body>
              {invoiceType === 'all-unpaid-invoices' && (
                <>
                  <UnPaidInvoices
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    invoices={allUnPaidInvoices}
                  />
                  <Card.Header className="rounded-0 border-0 text-sm py-1 my-3 bg-dark text-white">
                    TOTAL AMOUNT TO PAY
                    <div className="card-options text-white">
                      {`${totalInvoiceDue.toLocaleString()} UGX`}
                    </div>
                  </Card.Header>
                </>
              )}

              {invoiceType === 'select-unpaid-invoices' && (
                <>
                  <UnPaidInvoices
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    invoices={allUnPaidInvoices}
                    editAmount
                  />
                  <Card.Header className="fw-bold border-0 text-sm py-1 my-3 bg-dark text-white">
                    TOTAL AMOUNT TO PAY
                    <div className="card-options text-white">
                      {`${sumBy(selectedRows, 'amount').toLocaleString()} UGX`}
                    </div>
                  </Card.Header>
                </>
              )}

              {invoiceType === 'future-payments' && (
                <InputText
                  name="amount"
                  label="AMOUNT TO DEPOSIT"
                  inline
                  register={register({
                    required: 'Enter the amount to Deposit E.g 1,000,000',
                    min: {
                      value: 500,
                      message: 'Minimum amount should be 500 UGX',
                    },
                    max: {
                      value: 100000000,
                      message: 'Maximum amount should be 100,000,000 UGX',
                    },
                  })}
                  type="number"
                  min="500"
                  max="100000000"
                  error={get(errors, 'amount.message')}
                  requiredField
                />
              )}
            </Card.Body>
            <Card.Footer className="text-end border-top-0">
              <SubmitButton
                text="GENERATE PRN"
                onClick={handleSubmit(handleFormSubmit)}
                size="sm"
                loading={generatingPaymentReference}
                loadingText="Generating..."
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}

GeneratePaymentReference.defaultProps = {
  activeTab: null,
};

GeneratePaymentReference.propTypes = {
  activeTab: propTypes.striing,
  navigateUser: PropTypes.func.isRequired,
};

export default GeneratePaymentReference;
