import { isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FaPaperclip } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
  TabMenu,
} from '../../../components/common';
import { paymentActions, settingActions } from '../../../config/actions';
import MenuList from '../../../config/routes/MenuList';
import TransactionsTable from './TransactionsTable';

function MyTransactions() {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.myTransaction);
  const [currentMenu, setCurrentMenu] = useState('data');
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);

  const getTransactions = () => dispatch(paymentActions.getMyTransactions());

  const menuItems = [
    {
      title: 'Invoice Payments',
      action: 'data',
    },
    {
      title: 'Fees Deposits',
      action: 'deposits',
    },
  ];

  const navigateUser = (route) => {
    const { MyTransaction } = MenuList;
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'payments',
        menuLevel_2: MyTransaction?.label,
        menuLevel_3: route,
      })
    );
  };

  useEffect(() => {
    if (isEmpty(transactions)) getTransactions();
  }, []);
  useEffect(() => {
    const { key } = selectedMenu;

    if (key === 'my-transactions') {
      navigateUser(menuItems[0]?.title);
    }
  }, []);

  return (
    <Card>
      <TabMenu
        currentMenu={currentMenu}
        setCurrentMenu={setCurrentMenu}
        navigateUser={navigateUser}
        menus={menuItems}
      >
        <div className="card-options">
          <ReloadButton loading={loading} onClick={() => getTransactions()} />
        </div>
      </TabMenu>

      <Card.Body className="p-0">
        {loading && isEmpty(transactions[currentMenu]) ? (
          <DataSpinner />
        ) : (
          <>
            {isEmpty(transactions[currentMenu]) ? (
              <DataNotFound />
            ) : (
              <Accordion flush>
                {map(transactions[currentMenu], (transaction) => (
                  <Accordion.Item
                    eventKey={
                      currentMenu === 'deposits'
                        ? `${transaction.transaction_date}`
                        : `${transaction.programme_study_years}-${transaction.semester_is}-${transaction.academic_year_id}`
                    }
                    key={
                      currentMenu === 'deposits'
                        ? `${transaction.transaction_date}`
                        : `${transaction.programme_study_years}-${transaction.semester_is}-${transaction.academic_year_id}`
                    }
                  >
                    <Accordion.Header>
                      <FaPaperclip className="me-2" />
                      {currentMenu === 'deposits'
                        ? `${transaction.transaction_date} Fees Deposits`
                        : `${transaction.programme_study_years} - ${transaction.semester} - ${transaction.academic_year}`}
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <TransactionsTable data={transaction} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default MyTransactions;
