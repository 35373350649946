import { isEmpty, sum, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Modal,
  ModalBody,
  Spinner,
} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  CustomModal,
  SubmitButton,
} from '../../components/common';
import {
  appActions,
  enrollmentActions,
  paymentActions,
  settingActions,
} from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import ProgrammeTab from './ProgrammeTab';
import AddressTab from './AddressTab';

function Header() {
  const dispatch = useDispatch();
  const { currentStudentProgramme, accountBalance } = useSelector(
    (state) => state.auth
  );
  const { gettingCurrentSemester, currentSemester } = useSelector(
    (state) => state.app
  );
  const showModal = useSelector((state) => state.setting.showModal);
  const { invoices, shouldAllocate } = useSelector((state) => state.myInvoice);
  const [invoiceTotalDue, setInvoiceTotalDue] = useState(0);

  const getInvoices = () => {
    dispatch(paymentActions.getMyInvoices());
  };

  const reloadCurrentSemester = () => {
    dispatch(appActions.getCurrentSemester());
    if (!isEmpty(currentStudentProgramme))
      dispatch(enrollmentActions.getCurrentEvents(currentStudentProgramme?.id));
  };

  useEffect(() => {
    if (isEmpty(currentSemester)) {
      reloadCurrentSemester();
    }
    if (isEmpty(invoices)) getInvoices();
  }, []);

  useEffect(() => {
    if (!isEmpty(invoices)) {
      let invoiceAmountsDue = 0;

      invoices.forEach((invoice) => {
        const tuitionDue = sumBy(invoice.tuition_invoices, 'amount_due');
        const functionalDue = sumBy(
          invoice.functional_fees_invoices,
          'amount_due'
        );

        const otherFeesDue = sumBy(invoice.other_fees_invoices, 'amount_due');
        const manualsDue = sumBy(invoice.manual_invoices, 'amount_due');

        invoiceAmountsDue += sum([
          tuitionDue,
          functionalDue,
          otherFeesDue,
          manualsDue,
        ]);
      });
      setInvoiceTotalDue(invoiceAmountsDue);
      dispatch(paymentActions.setInvoiceTotalDue(invoiceAmountsDue));

      if (invoiceAmountsDue > 0 && accountBalance > 0) {
        dispatch(settingActions.setShowModal(true));
        dispatch(paymentActions.setShouldAllocateFunds(true));
      } else {
        dispatch(paymentActions.setShouldAllocateFunds(false));
      }
    }
  }, [invoices, accountBalance]);

  const handleActions = () => {
    dispatch(settingActions.setShowModal(false));
    dispatch(settingActions.setSelectedMenu(MenuList.MyInvoice));
  };

  const buttonOptions = [
    {
      id: 1,
      title: 'Current Yr.',
      value: currentSemester?.academic_year,
      color: 'info',
    },
    {
      id: 2,
      title: 'Current Sem.',
      value: currentSemester?.semester,
      color: 'info',
    },
    {
      id: 3,
      title: currentSemester?.is_enrolled ? <HiCheckCircle /> : <HiXCircle />,
      value: currentSemester?.is_enrolled ? 'ENROLLED' : 'NOT ENROLLED',
      color: currentSemester?.is_enrolled ? 'success' : 'danger',
    },
    {
      id: 4,
      title: currentSemester?.is_registered ? <HiCheckCircle /> : <HiXCircle />,
      value: currentSemester?.is_registered ? 'REGISTERED' : 'NOT REGISTERED',
      color: currentSemester?.is_registered ? 'success' : 'danger',
    },
  ];
  return (
    <>
      <ProgrammeTab />
      <Card className="border-top border-bottom border-start-0 border-end-0">
        <Card.Header className="py-2 px-3 border-0 mb-0">
          {gettingCurrentSemester && isEmpty(currentSemester) ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <span>
              {isEmpty(currentSemester) ? (
                <AlertMessage
                  message="THERE'S CURRENTLY NO RUNNING ACADEMIC YEAR"
                  className="m-0 p-1 px-2 text-sm"
                />
              ) : (
                <>
                  {buttonOptions.map((buttonOption) => (
                    <ButtonGroup
                      size="sm"
                      className="rounded-0 me-2 academic-status"
                      key={buttonOption.id}
                    >
                      <Button
                        variant="dark"
                        size="sm"
                        className="text-sm text-uppercase mb-1"
                      >
                        {buttonOption.title}
                      </Button>
                      <Button
                        variant={buttonOption.color}
                        size="sm"
                        className="text-sm fw-bold text-white text-uppercase mb-1"
                      >
                        {buttonOption.icon && buttonOption.icon}
                        {buttonOption.value}
                      </Button>
                    </ButtonGroup>
                  ))}
                </>
              )}
            </span>
          )}
          <div className="card-options">
            <Button
              variant="secondary"
              size="sm"
              className="text-sm text-uppercase font600 me-2"
            >
              TOTAL FEES BAL DUE
              <span className="text-danger">
                {`: ${parseInt(invoiceTotalDue, 10).toLocaleString()}/=`}
              </span>
            </Button>
            <Button
              variant="info"
              size="sm"
              className="text-sm text-uppercase font600"
            >
              {`BALANCE ON ACCOUNT: ${parseInt(
                accountBalance,
                10
              ).toLocaleString()}/=`}
            </Button>
          </div>
        </Card.Header>
      </Card>
      <AddressTab />
      {shouldAllocate && showModal && (
        <>
          <CustomModal
            show={shouldAllocate && showModal}
            size="lg"
            title="ACCESS LIMITED!"
            onCloseModal={handleActions}
            backdrop="static"
            keyboard={false}
          >
            <ModalBody>
              <AlertMessage
                variant="info"
                className="fs-3"
                message="Access to your portal has been limited because you
               have pending invoices and unallocated funds"
              />
              <div className="d-flex mt-5">
                <span className="fs-5 text-info">
                  <FaInfoCircle className="me-2 fs-4" />
                  Kindly allocate all funds on your account to the pending
                  invoices
                </span>
              </div>
            </ModalBody>
            <Modal.Footer>
              <SubmitButton
                variant="warning"
                type="button"
                text="ALLOCATE"
                onClick={handleActions}
              />
            </Modal.Footer>
          </CustomModal>
        </>
      )}
    </>
  );
}

export default Header;
