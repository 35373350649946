import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadButton, TabMenu } from '../../../components/common';
import { serviceActions, settingActions } from '../../../config/actions';
import ChangeOfProgrammeHistory from './ChangeOfProgrammeHistory';

function ServiceHistory() {
  const dispatch = useDispatch();
  const [currentMenu, setCurrentMenu] = useState('change-of-programme');
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);

  const onClickReload = () => {
    dispatch(serviceActions.getServiceHistory(currentMenu));
  };

  const navigateUser = (route) => {
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'services',
        menuLevel_2: selectedMenu?.label,
        menuLevel_3: route,
      })
    );
  };

  useEffect(() => {
    const { key } = selectedMenu;
    const activeServiceTab = currentMenu.split('-').join(' ');
    if (key === 'service-history') {
      navigateUser(activeServiceTab);
    }
  }, []);

  return (
    <Card>
      <TabMenu
        menus={[
          {
            title: 'CHANGE OF PROGRAMME',
            action: 'change-of-programme',
          },
        ]}
        setCurrentMenu={setCurrentMenu}
        currentMenu={currentMenu}
      >
        <div className="card-options">
          <ReloadButton onClick={onClickReload} />
        </div>
      </TabMenu>
      <Card.Body>
        {currentMenu === 'change-of-programme' && <ChangeOfProgrammeHistory />}
      </Card.Body>
    </Card>
  );
}

export default ServiceHistory;
