import update from 'immutability-helper';
import { paymentActions } from '../../actions';
import initialState from '../../initialState';

function myTransaction(state = initialState.myTransaction, actions) {
  switch (actions.type) {
    case paymentActions.GET_MY_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case paymentActions.GET_MY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: actions.data || [],
        loading: false,
      };
    case paymentActions.GET_MY_TRANSACTIONS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case paymentActions.GET_PREVIOUS_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loadError: {},
        gettingPreviousPayments: true,
      };
    case paymentActions.GET_PREVIOUS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        previousPayments: {
          ...state.previousPayments,
          [actions.category]: actions.data,
        },
        gettingPreviousPayments: false,
      };
    case paymentActions.GET_PREVIOUS_TRANSACTIONS_ERROR:
      return {
        ...state,
        previousPaymentError: actions.error,
        gettingPreviousPayments: false,
      };

    case paymentActions.SEARCH_PAYMENT_REQUEST:
      return {
        ...state,
        searchPaymentError: {},
        searchingPayment: true,
      };
    case paymentActions.SEARCH_PAYMENT_SUCCESS:
      return {
        ...state,
        searchPayment: actions.data,
        searchingPayment: false,
      };
    case paymentActions.SEARCH_PAYMENT_ERROR:
      return {
        ...state,
        searchPaymentError: actions.error,
        searchingPayment: false,
      };

    case paymentActions.ALLOCATE_TRANSACTION_REQUEST:
      return {
        ...state,
        allocationError: {},
        allocating: true,
      };
    case paymentActions.ALLOCATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        allocationSuccess: actions.data,
        allocating: false,
      };
    case paymentActions.ALLOCATE_TRANSACTION_ERROR:
      return {
        ...state,
        allocationError: actions.error,
        allocating: false,
      };

    case paymentActions.GET_MY_LEDGER_REQUEST:
      return {
        ...state,
        studentLedgerError: {},
        gettingLedger: true,
      };
    case paymentActions.GET_MY_LEDGER_SUCCESS:
      return {
        ...state,
        myStudentLedger: actions.data,
        gettingLedger: false,
      };
    case paymentActions.GET_MY_LEDGER_ERROR:
      return {
        ...state,
        studentLedgerError: actions.error,
        gettingLedger: false,
      };

    case paymentActions.OFFSET_STUDENT_INVOICE_REQUEST:
      return {
        ...state,
        offsetting: true,
        offsetError: {},
      };
    case paymentActions.OFFSET_STUDENT_INVOICE_SUCCESS:
      return {
        ...state,
        offsetSuccess: actions.data,
        offsetting: false,
      };
    case paymentActions.OFFSET_STUDENT_INVOICE_ERROR:
      return {
        ...state,
        offsetError: actions.error,
        offsetting: false,
      };

    case paymentActions.GET_FEES_STRUCTURE_REQUEST:
      return {
        ...state,
        feesStructureError: {},
        gettingFeesStructure: true,
      };
    case paymentActions.GET_FEES_STRUCTURE_SUCCESS: {
      const { data, studentProgrammeId } = actions;

      const { myFeesStructure } = state;

      let newState = state;

      const studentData = {
        studentProgrammeId,
        feesStructure: data,
      };

      const feesStructureIndex = myFeesStructure.findIndex(
        (structure) => structure.studentProgrammeId === studentProgrammeId
      );

      if (feesStructureIndex === -1) {
        newState = update(newState, {
          myFeesStructure: { $push: [studentData] },
        });
      } else {
        newState = update(newState, {
          myFeesStructure: {
            [feesStructureIndex]: { $set: studentData },
          },
        });
      }

      return { ...newState, feesStructure: {}, gettingFeesStructure: false };
    }

    case paymentActions.GET_FEES_STRUCTURE_ERROR:
      return {
        ...state,
        feesStructure: actions.error,
        gettingFeesStructure: false,
      };

    case paymentActions.OFFSET_INVOICE_REQUEST:
      return {
        ...state,
        offsetError: {},
        offsettingInvoice: true,
      };
    case paymentActions.OFFSET_INVOICE_SUCCESS:
      return {
        ...state,
        offsetInvoice: actions.data,
        offsettingInvoice: false,
      };
    case paymentActions.OFFSET_INVOICE_ERROR:
      return {
        ...state,
        offsetError: actions.error,
        offsettingInvoice: false,
      };
    case paymentActions.GENERATE_GRADUATION_INVOICE_REQUEST:
      return {
        ...state,
        generateError: {},
        generatingInvoice: true,
      };
    case paymentActions.GENERATE_GRADUATION_INVOICE_SUCCESS:
      return {
        ...state,
        generatedInvoice: actions.data,
        generatingInvoice: false,
      };
    case paymentActions.GENERATE_GRADUATION_INVOICE_ERROR:
      return {
        ...state,
        generateError: actions.error,
        generatingInvoice: false,
      };

    default:
      return state;
  }
}

export default myTransaction;
