const MenuList = Object.freeze({
  BioData: {
    label: 'Bio Data',
    action: 'bio-data',
    key: 'bio-data',
  },
  PaymentReference: {
    label: 'Generate PRN',
    action: 'payment-references',
    key: 'payment-references',
  },
  SelfEnrollment: {
    label: 'Enroll or Register',
    action: 'self-enrollment',
    key: 'self-enrollment',
  },
  EnrollmentHistory: {
    label: 'Enrollment History',
    action: 'enrollment-history',
    key: 'enrollment-history',
  },
  RegistrationHistory: {
    label: 'Registration History',
    action: 'registration-history',
    key: 'registration-history',
  },
  MigratedEnrollment: {
    label: 'Migrated History',
    action: 'migrated-records',
    key: 'migrated-records',
  },
  MyInvoice: {
    label: 'My Bills/Invoices',
    action: 'my-invoices',
    key: 'my-invoices',
  },
  MyTransaction: {
    label: 'My Transactions',
    action: 'my-transactions',
    key: 'my-transactions',
  },
  PreviousTransaction: {
    label: 'Migrated Transactions',
    action: 'previous-transactions',
    key: 'previous-transactions',
  },
  studentPaymentLedger: {
    label: 'My Student Ledger',
    action: 'student-ledger',
    key: 'student-ledger',
  },
  myFeesStructure: {
    label: 'My Fees Structure',
    action: 'fees-structure',
    key: 'fees-structure',
  },
  AcademicCalendar: {
    label: 'Academic Calendar',
    action: 'academic-calendar',
    key: 'academic-calendar',
  },
  Services: {
    label: 'Apply For Services',
    action: 'apply-for-services',
    key: 'apply-for-services',
  },
  ServiceHistory: {
    label: 'Service History',
    action: 'service-history',
    key: 'service-history',
  },
  MyResults: {
    label: 'My Results',
    action: 'my-results',
    key: 'my-results',
  },
  SearchPayment: {
    label: 'Check PRN Status',
    action: 'search-payment',
    key: 'search-payment',
  },
});

export default MenuList;
