import React from 'react';
import PropTypes, { array } from 'prop-types';
import { Table, InputNumber } from 'antd';
import { uniqBy } from 'lodash';

function UnPaidInvoices({
  invoices,
  setSelectedRows,
  selectedRows,
  editAmount,
}) {
  const updateCellQuantity = (amount, row) => {
    setSelectedRows(
      uniqBy(
        [
          {
            id: row.id,
            invoice_number: row.invoice_number,
            amount: parseInt(amount, 10),
          },
          ...selectedRows,
        ],
        'invoice_number'
      )
    );
  };
  const columns = [
    {
      title: 'INVOICE NO',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: 150,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'invoice_amount',
      key: 'invoice_amount',
      width: 150,
      ellipsis: true,
      render(text, row) {
        return <>{`${parseInt(text, 10).toLocaleString()} ${row.currency}`}</>;
      },
    },
    {
      title: 'PAID',
      dataIndex: 'amount_paid',
      key: 'amount_paid',
      width: 150,
      render(text, row) {
        return <>{`${parseInt(text, 10).toLocaleString()} ${row.currency}`}</>;
      },
    },
    {
      title: 'AMOUNT DUE',
      dataIndex: 'amount_due',
      key: 'amount_due',
      width: 150,
      render(text, row) {
        return <>{`${parseInt(text, 10).toLocaleString()} ${row.currency}`}</>;
      },
    },
  ];

  if (editAmount)
    columns.push({
      title: 'AMOUNT TO PAY',
      key: 'amount_due',
      editable: true,
      width: 100,
      fixed: 'right',
      render(text, record) {
        return (
          <InputNumber
            min={1}
            max={record.amount_due}
            defaultValue={record.amount_due}
            onChange={(quantity) => updateCellQuantity(quantity, record)}
            key={selectedRows.find(
              (row) => row.invoice_number === record.invoice_number
            )}
          />
        );
      },
    });

  return (
    <Table
      dataSource={invoices}
      columns={columns}
      size="small"
      pagination={false}
      bordered
      rowKey="invoice_number"
      tableLayout="auto"
      className="border-0 text-sm"
      rowClassName="text-sm"
      scroll={{
        y: '65vh',
        x: '100vw',
      }}
    />
  );
}

UnPaidInvoices.defaultProps = {
  invoices: [],
  selectedRows: [],
  editAmount: false,
};

UnPaidInvoices.propTypes = {
  invoices: PropTypes.oneOfType([array]),
  selectedRows: PropTypes.oneOfType([array]),
  setSelectedRows: PropTypes.func.isRequired,
  editAmount: PropTypes.bool,
};

export default UnPaidInvoices;
