import { find, isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FaPaperclip } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
  TabMenu,
} from '../../components/common';
import AccordionHeader from '../../components/common/AccordionHeader';
import { paymentReferenceActions, settingActions } from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import GeneratePaymentReference from './GeneratePaymentReference';
import ReferenceModal from './ReferenceModal';
import SinglePaymentReference from './SinglePaymentReference';

function PaymentReferences() {
  const dispatch = useDispatch();
  const { paymentReferences, gettingPaymentReferences } = useSelector(
    (state) => state.paymentReference
  );
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const [activeKey, setActiveKey] = useState(1);
  const [activeTab, setActiveTab] = useState('generate-reference');
  const [referenceTab, setReferenceTab] = useState('active');

  const menuItems = [
    {
      title: 'Generate New PRN',
      action: 'generate-reference',
    },
    {
      title: 'My Payment REFs',
      action: 'references',
    },
  ];

  const reloadPaymentReferences = () => {
    dispatch(paymentReferenceActions.getPaymentReferences());
  };

  useEffect(() => {
    if (isEmpty(paymentReferences)) reloadPaymentReferences();
  }, []);

  const navigateUser = (route, route2 = null) => {
    const {
      PaymentReference: { label },
    } = MenuList;

    if (!isEmpty(label)) {
      dispatch(
        settingActions.setNavigation({
          ...appNavigation,
          menuLevel_1: label,
          menuLevel_2: route,
          menuLevel_3: route2,
        })
      );
    }
  };

  useEffect(() => {
    const { key } = selectedMenu;
    const { title, action } = find(
      menuItems,
      (item) => item.action === activeTab
    );
    const activeSubmmenu =
      action === 'references' ? 'Active references' : 'To make full payment';

    if (key === 'payment-references') {
      navigateUser(title, activeSubmmenu);
    }
  }, [activeTab]);

  return (
    <>
      <Card>
        <TabMenu
          currentMenu={activeTab}
          setCurrentMenu={setActiveTab}
          navigateUser={navigateUser}
          menus={menuItems}
        />
        <Card.Body className="overflow-auto">
          {activeTab === 'references' && (
            <Card className="border-0">
              <TabMenu
                className="bg-white border"
                variant="pills"
                currentMenu={referenceTab}
                setCurrentMenu={setReferenceTab}
                navigateUser={navigateUser}
                menus={[
                  {
                    title: `Active References (${
                      paymentReferences?.active?.length || 0
                    })`,
                    action: 'active',
                  },
                  {
                    title: `Expired References (${
                      paymentReferences?.inactive?.length || 0
                    })`,
                    action: 'inactive',
                  },
                ]}
              >
                <div className="card-options">
                  <ReloadButton
                    loading={gettingPaymentReferences}
                    onClick={reloadPaymentReferences}
                  />
                </div>
              </TabMenu>

              {gettingPaymentReferences &&
              isEmpty(paymentReferences[referenceTab]) ? (
                <DataSpinner />
              ) : (
                <>
                  {isEmpty(paymentReferences[referenceTab]) ? (
                    <DataNotFound
                      message={`You have No ${referenceTab} Payment References`}
                    />
                  ) : (
                    <Accordion defaultActiveKey="1" activeKey={activeKey}>
                      {orderBy(
                        paymentReferences[referenceTab],
                        ['ura_prn'],
                        'desc'
                      ).map((paymentReference) => (
                        <Accordion.Item
                          className="mb-0"
                          key={paymentReference.id}
                          eventKey={paymentReference?.id}
                        >
                          <AccordionHeader
                            activeKey={activeKey}
                            eventKey={paymentReference?.id}
                            setActiveKey={setActiveKey}
                          >
                            <FaPaperclip className="me-2" />
                            REFERENCE:
                            <span className="text-danger ms-1">
                              {paymentReference.ura_prn}
                            </span>
                            , AMOUNT TO PAY:
                            <strong className="text-danger mx-1">
                              {parseInt(
                                paymentReference.amount,
                                10
                              ).toLocaleString()}
                            </strong>
                            UGX, EXPIRY DATE:
                            <span className="mx-1 text-danger">
                              {`${paymentReference.expiry_date}`}
                            </span>
                            , GENERATED BY:
                            <span className="ms-1 text-danger">
                              {`${
                                paymentReference.generated_by === 'STUDENT'
                                  ? 'SELF'
                                  : paymentReference.generated_by
                              }`}
                            </span>
                          </AccordionHeader>
                          <Accordion.Body>
                            <SinglePaymentReference
                              paymentReference={paymentReference}
                              key={paymentReference.id}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                </>
              )}
            </Card>
          )}
          {activeTab === 'generate-reference' && (
            <GeneratePaymentReference
              activeTab={activeTab}
              navigateUser={navigateUser}
            />
          )}
        </Card.Body>
      </Card>
      <ReferenceModal switchTab={() => setActiveTab('references')} />
    </>
  );
}

export default PaymentReferences;
