/* eslint-disable react/react-in-jsx-scope */
import {
  CalendarOutlined,
  DollarCircleOutlined,
  EditOutlined,
  FieldBinaryOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  ReconciliationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  FaClipboardList,
  FaPaperclip,
  FaRegEdit,
  FaSearchDollar,
  FaTasks,
  FaUserEdit,
  FaUserGraduate,
} from 'react-icons/fa';
import MenuList from '../../config/routes/MenuList';

const sideBarMenu = [
  {
    ...MenuList.PaymentReference,
    icon: <FieldBinaryOutlined />,
  },
  {
    ...MenuList.SearchPayment,
    icon: <FaSearchDollar />,
  },
  {
    label: 'Enrollment & Registration',
    key: 'registration-and-enrollment',
    icon: <EditOutlined />,
    children: [
      {
        ...MenuList.SelfEnrollment,
        icon: <FaUserEdit />,
      },
      {
        ...MenuList.EnrollmentHistory,
        icon: <FileDoneOutlined />,
      },
      {
        ...MenuList.RegistrationHistory,
        icon: <FileDoneOutlined />,
      },
      {
        ...MenuList.MigratedEnrollment,
        icon: <FileDoneOutlined />,
      },
    ],
  },
  {
    label: 'Payments',
    key: 'payments',
    icon: <DollarCircleOutlined />,
    children: [
      {
        ...MenuList.MyInvoice,
        icon: <FaPaperclip />,
      },
      {
        ...MenuList.MyTransaction,
        icon: <FileProtectOutlined />,
      },
      {
        ...MenuList.PreviousTransaction,
        icon: <FileProtectOutlined />,
      },
      {
        ...MenuList.studentPaymentLedger,
        icon: <FileProtectOutlined />,
      },
      {
        ...MenuList.myFeesStructure,
        icon: <FileProtectOutlined />,
      },
    ],
  },
  {
    label: 'My Programme',
    key: 'my-programmes',
    icon: <FaUserGraduate />,
    children: [
      {
        ...MenuList.MyResults,
        icon: <FaTasks />,
      },
    ],
  },
  {
    label: 'Services',
    key: 'services',
    icon: <ReconciliationOutlined />,
    children: [
      {
        ...MenuList.Services,
        icon: <FaRegEdit />,
      },
      {
        ...MenuList.ServiceHistory,
        icon: <FaClipboardList />,
      },
    ],
  },
  {
    ...MenuList.BioData,
    icon: <UserOutlined />,
  },
  {
    ...MenuList.AcademicCalendar,
    icon: <CalendarOutlined />,
  },
];

export default sideBarMenu;
