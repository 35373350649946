import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, ModalBody, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaEdit, FaUserGraduate } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage, InputText, ReloadButton } from '../../components/common';
import FormModal from '../../components/common/FormModal';
import { authActions, settingActions } from '../../config/actions';

function BioData({ authUser }) {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { updating, gettingAuthUser } = useSelector((state) => state.auth);
  const { showModal } = useSelector((state) => state.setting);
  const [bioData, setBioData] = useState({});

  const updateStudentDetails = (data) => {
    dispatch(authActions.updateBioData(data));
  };

  useEffect(() => {
    setBioData(authUser);
  }, [authUser]);

  return (
    <>
      <Card.Header className="text-primary font600 py-2 text-sm bioData-header">
        <FaUserGraduate className="me-1" />
        MY PROFILE DETAILS
        <div className="card-options">
          <Button
            variant="outline-info"
            className="me-2"
            onClick={() => dispatch(settingActions.setShowModal(true))}
          >
            <FaEdit className="me-1" /> Edit Contacts
          </Button>
          <ReloadButton
            loading={gettingAuthUser}
            onClick={() => dispatch(authActions.getAuthUser())}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6} xs={6}>
            <InputText
              label="Surname"
              defaultValue={bioData.surname}
              disabled
              inline
            />
            <InputText
              label="Other Names"
              defaultValue={bioData.other_names}
              disabled
              inline
            />
            <InputText
              label="Email"
              name="email"
              defaultValue={bioData.email}
              disabled
              inline
            />
            <InputText
              label="Telephone Number"
              name="phone"
              defaultValue={bioData.phone}
              disabled
              inline
            />
            <InputText
              label="Student No."
              defaultValue={bioData.student_number}
              disabled
              inline
            />
            <InputText
              label="Reg. No."
              defaultValue={bioData.registration_number}
              disabled
              inline
            />
          </Col>
          <Col md={6} xs={6}>
            <InputText
              label="Gender"
              defaultValue={bioData.gender}
              disabled
              inline
            />
            <InputText
              label="Date Of Birth"
              defaultValue={bioData.date_of_birth}
              disabled
              inline
            />
            <InputText
              label="Religion"
              defaultValue={bioData.religion}
              disabled
              inline
            />
            <InputText
              label="Home District"
              defaultValue={bioData.home_district}
              disabled
              inline
            />
            <InputText
              label="Nationality"
              defaultValue={bioData.nationality}
              disabled
              inline
            />
            <InputText
              label="National Id No."
              defaultValue={bioData.national_id_number}
              disabled
              inline
            />
            <InputText
              label="Passport No."
              defaultValue={bioData.passport_id_number}
              disabled
              inline
            />
          </Col>
        </Row>
      </Card.Body>
      {showModal && (
        <FormModal
          formTitle="UPDATE CONTACT DETAILS"
          defaultShow={showModal}
          backdrop="static"
          size="lg"
          onCloseModal={() => dispatch(settingActions.setShowModal(false))}
          handleSubmit={handleSubmit(updateStudentDetails)}
          submitButtonProps={{
            text: 'Update',
            loading: updating,
            loadingText: 'Updating...',
          }}
        >
          <ModalBody>
            <AlertMessage
              variant="info"
              message="Note: Please use your own email and phone number(not for another person), 
              otherwise you will not get messages from the system."
            />
            <InputText
              label="Email"
              name="email"
              register={register}
              defaultValue={bioData.email}
              inline
            />
            <InputText
              label="Telephone Number"
              name="phone"
              register={register}
              defaultValue={bioData.phone}
              inline
            />
          </ModalBody>
        </FormModal>
      )}
    </>
  );
}

BioData.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default BioData;
