import { isArray, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaCheckCircle, FaDownload } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  DataNotFound,
  DataSpinner,
  InputText,
  SubmitButton,
} from '../../../components/common';
import { serviceActions } from '../../../config/actions';

function ChangeOfProgrammeHistory() {
  const dispatch = useDispatch();
  const { serviceHistories, gettingServiceHistory, downloadingServiceLetter } =
    useSelector((state) => state.service);

  const [changeOfProgrammes, setChangeOfProgrammes] = useState([]);
  const serviceType = 'change-of-programme';

  useEffect(() => {
    dispatch(serviceActions.getServiceHistory(serviceType));
  }, []);

  useEffect(() => {
    if (isArray(serviceHistories)) {
      const getCOP = serviceHistories.find(
        (service) => service.category === serviceType
      );
      setChangeOfProgrammes(getCOP?.data || []);
    } else setChangeOfProgrammes([]);
  }, [serviceHistories]);

  const downloadAcceptanceLetter = (serviceId) => {
    if (serviceId) {
      dispatch(
        serviceActions.downloadServiceAcceptanceLetter(serviceType, serviceId)
      );
    }
  };

  return (
    <div>
      {gettingServiceHistory && isEmpty(changeOfProgrammes) ? (
        <DataSpinner text="Loading History, please wait" />
      ) : (
        <>
          {isEmpty(changeOfProgrammes) ? (
            <DataNotFound message="You have no Service History" />
          ) : (
            <>
              {changeOfProgrammes.map((application) => (
                <Card body key={application.id} className="mb-4">
                  {application.request_status === 'ACCEPTED' && (
                    <AlertMessage
                      variant="success"
                      className="font600 fs-6 rounded"
                      icon={<FaCheckCircle className="fs-4 me-2" />}
                      message="Congratulations your CHANGE OF PROGRAMME Request has been accepted, Please Download your Acceptance letter below, fill it and Submit to Senate for Final approval"
                    >
                      <div className="text-center mt-3">
                        <SubmitButton
                          text="DOWNLOAD ACCEPTANCE LETTER"
                          size="sm"
                          type="button"
                          onClick={() =>
                            downloadAcceptanceLetter(application.id)
                          }
                          loading={downloadingServiceLetter}
                          loadingText="Downloading..."
                          className="text-xs me-2 fw-semibold text-uppercase"
                          iconBefore={<FaDownload className="me-2" />}
                        />
                      </div>
                    </AlertMessage>
                  )}
                  {application.request_status === 'APPROVED' && (
                    <AlertMessage
                      variant="success"
                      className="font600 fs-6 rounded"
                      message="Your CHANGE OF PROGRAMME has been Approved"
                      icon={<FaCheckCircle className="fs-4 me-2" />}
                    />
                  )}
                  <Row>
                    <Col md={12}>
                      <InputText
                        label="CURRENT PROGRAMME"
                        defaultValue={`${application?.studentProgramme?.programme?.programme_title} - (${application?.studentProgramme?.programme?.programme_code})`}
                        disabled
                        inline
                      />
                      <InputText
                        label="NEW PROGRAMME"
                        defaultValue={`${application.newProgramme?.programme_title} - (${application.newProgramme?.programme_code})`}
                        disabled
                        inline
                      />
                      <InputText
                        label="CAMPUS"
                        defaultValue={application.newCampus?.metadata_value}
                        disabled
                        inline
                      />
                      <InputText
                        label="NEW STUDY YEAR"
                        defaultValue={
                          application.studyYear?.programme_study_years
                        }
                        disabled
                        inline
                      />
                      {!isEmpty(application.newSubjectComb) && (
                        <InputText
                          label="NEW SUBJECT COMBINATIONS"
                          defaultValue={map(
                            application.newSubjectComb?.subjects,
                            (e) => e?.subject?.subject_code
                          ).join('/')}
                          disabled
                          inline
                        />
                      )}
                      <InputText
                        label="ACADEMIC YEAR"
                        defaultValue={application.academicYear?.metadata_value}
                        disabled
                        inline
                      />
                      <InputText
                        label="APPLICATION DATE"
                        defaultValue={application.created_at}
                        disabled
                        inline
                      />
                      <InputText
                        label="APPLICATION STATUS"
                        defaultValue={application?.request_status}
                        disabled
                        inline
                      />
                      <InputText
                        label="AMOUNT BILLED"
                        defaultValue={parseInt(
                          application?.amount,
                          10
                        ).toLocaleString()}
                        disabled
                        inline
                      />
                      <InputText
                        label="AMOUNT PAID"
                        defaultValue={parseInt(
                          application?.paid,
                          10
                        ).toLocaleString()}
                        disabled
                        inline
                      />
                      <InputText
                        label="REASON FOR CHANGE"
                        type="textarea"
                        defaultValue={application?.reason || 'N/A'}
                        disabled
                        inline
                      />
                      <InputText
                        label="PAYMENT REFERENCE"
                        defaultValue={application?.ura_prn || 'NOT GENERATED'}
                        disabled
                        inline
                        key={application?.ura_prn}
                      />
                      <InputText
                        label="PAYMENT STATUS"
                        defaultValue={
                          application?.payment_status === 'T'
                            ? 'RECEIVED AND NOTIFIED'
                            : 'NOT PAID'
                        }
                        disabled
                        inline
                      />
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ChangeOfProgrammeHistory;
