import { Button } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaCheckCircle, FaExternalLinkAlt, FaFolderPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AntDTable } from '../../../components/common';
import { settingActions } from '../../../config/actions';
import AllocationModal from './AllocationModal';
import PaymentReceiptModal from './PaymentReceiptModal';

function TransactionsTable({ data }) {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.setting);
  const { authUser } = useSelector((state) => state.auth);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [showPaymentReceiptModal, setShowPaymentReceiptModal] = useState(false);
  const [currentReceipt, setCurrentReceipt] = useState({});

  const showAllocateModal = (rowData) => {
    setSelectedAmount(rowData);
    dispatch(settingActions.setShowModal(true));
  };

  const openReceiptModal = (paymentData) => {
    const dataToSend = {
      full_name: `${authUser.surname} ${authUser.other_names}`,
      ura_prn: paymentData.reference_number,
      currency: paymentData.currency,
      amount: paymentData.amount_paid,
      bank: paymentData.bank_name,
      branch: paymentData.bank_branch,
      payment_date: paymentData.payment_date,
      student_number: authUser.student_number,
      email: authUser.email,
      phone_number: authUser.phone,
      transaction_origin: paymentData.transaction_origin,
      created_by: `${paymentData.created_by_salutation} ${paymentData.created_by_surname}
       ${paymentData.created_by_other_names}`,
    };
    setCurrentReceipt(dataToSend);
    setShowPaymentReceiptModal(true);
  };

  const receiptButton = useCallback(
    (text, rowData) => (
      <Button
        size="small"
        icon={<FaExternalLinkAlt className="me-2" />}
        onClick={() => openReceiptModal(rowData)}
        color="blue"
        disabled
      >
        OPEN
      </Button>
    ),
    []
  );

  const allocateButton = useCallback(
    (text, rowData) => (
      <Button
        icon={
          rowData.unallocated_amount <= 0 ? (
            <FaCheckCircle className="text-sm me-1" />
          ) : (
            <FaFolderPlus className="text-sm me-2" />
          )
        }
        size="small"
        ghost={rowData.unallocated_amount < 1}
        className="fw-normal text-sm"
        disabled={rowData.unallocated_amount < 1}
        onClick={() => showAllocateModal(rowData)}
      >
        {rowData.unallocated_amount < 1 ? 'Fully Allocated' : 'ALLOCATE'}
      </Button>
    ),
    []
  );

  const columns = [
    {
      title: 'ALLOCATE',
      key: 'allocate',
      width: 140,
      render: allocateButton,
    },
    {
      title: 'RECEIPT',
      key: 'view_receipt',
      width: 120,
      render: receiptButton,
    },
    {
      title: 'PRN',
      key: 'reference_number',
      width: 120,
      render(row) {
        return <>{row.reference_number || '-------------'}</>;
      },
    },
    {
      title: 'MODE REFERENCE',
      key: 'mode_reference',
      width: 200,
      dataIndex: 'mode_reference',
    },
    {
      title: 'ORIGIN',
      dataIndex: 'transaction_origin',
      key: 'transaction_origin',
      width: 100,
    },
    {
      title: 'AMOUNT PAID',
      key: 'amount_paid',
      width: 120,
      render(row) {
        return <>{parseInt(row.amount_paid, 10).toLocaleString()}</>;
      },
    },
    {
      title: 'CURR',
      width: 70,
      key: 'currency',
      dataIndex: 'currency',
      responsive: ['sm'],
    },
    {
      title: 'ALLOCATED',
      key: 'allocated_amount',
      width: 100,
      render(row) {
        return <>{parseInt(row.allocated_amount, 10).toLocaleString()}</>;
      },
      responsive: ['sm'],
    },
    {
      title: 'BALANCE',
      key: 'unallocated_amount',
      width: 100,
      render(row) {
        return <>{parseInt(row.unallocated_amount, 10).toLocaleString()}</>;
      },
      responsive: ['sm'],
    },
    {
      title: 'BANK',
      dataIndex: 'bank_name',
      key: 'bank_name',
      responsive: ['sm'],
    },
    {
      title: 'BANK BR.',
      key: 'bank_branch',
      render(row) {
        return <>{row.bank_branch}</>;
      },
      responsive: ['sm'],
    },
    {
      title: 'PAYMENT DATE',
      key: 'payment_date',
      render(row) {
        const { payment_date: paymentDate } = row;
        return <>{moment(paymentDate).format('MM-DD-YYYY')}</>;
      },
      width: 150,
    },
  ];
  return (
    <>
      <AntDTable
        data={data.payment_transactions}
        columns={columns}
        pagination={false}
      />

      {showModal && !isEmpty(selectedAmount) && (
        <AllocationModal selectedAmount={selectedAmount} />
      )}
      {showPaymentReceiptModal && !isEmpty(currentReceipt) && (
        <PaymentReceiptModal
          setShowModal={setShowPaymentReceiptModal}
          selectedReceipt={currentReceipt}
          showModal={showPaymentReceiptModal}
        />
      )}
    </>
  );
}

TransactionsTable.defaultProps = {
  data: {},
};

TransactionsTable.propTypes = {
  data: PropTypes.oneOfType([object]),
};

export default TransactionsTable;
