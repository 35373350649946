import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { InputText } from '../../components/common';
import { paymentActions, settingActions } from '../../config/actions';

import CourseUnitsTable from './CourseUnitsTable';

function SingleRegistrationHistory({
  registrationHistory,
  setSelectedHistory,
}) {
  const { invoices, invoiceTotalDue } = useSelector((state) => state.myInvoice);

  const dispatch = useDispatch();

  const getInvoices = () => {
    dispatch(paymentActions.getMyInvoices());
  };

  useEffect(() => {
    if (isEmpty(invoices)) getInvoices();
  }, []);

  const downloadHandler = (cardType) => {
    if (cardType === 'examPermit' && invoiceTotalDue > 0) {
      toast.error(
        'You cannot print an Examination Permit with an Outstanding Fees Balance!.',
        {
          autoDismiss: true,
          appearance: 'warning',
          autoDismissTimeout: 6000,
        }
      );
    } else {
      dispatch(settingActions.setShowModal(true));
      dispatch(settingActions.setRegistrationCardType(cardType));

      let courseUnits = [];

      if (registrationHistory.course_units) {
        courseUnits = registrationHistory.course_units;

        setSelectedHistory({
          ...registrationHistory,
          courseUnits,
        });
      }
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <InputText
            label="REGISTRATION TYPE"
            defaultValue={
              registrationHistory?.registrationType?.metadata_value ||
              registrationHistory.registration_type
            }
            disabled
          />
        </Col>
        <Col md={4}>
          <InputText
            label="REGISTERED BY"
            defaultValue={
              registrationHistory.registered_by === 'STUDENT'
                ? 'SELF'
                : registrationHistory.registered_by
            }
            disabled
          />
        </Col>
        <Col md={4}>
          <InputText
            label="DATE OF REG."
            defaultValue={moment(registrationHistory?.created_at).format(
              'ddd, MMM Do YYYY, h:mm:ss a'
            )}
            disabled
          />
        </Col>
      </Row>

      <Card className="mt-2">
        <Card.Header className="py-2 text-info font600 text-xs">
          COURSE/MODULES REGISTERED
          {registrationHistory.is_active &&
            !isEmpty(registrationHistory.course_units) === true && (
              <div className="card-options">
                <Button
                  size="sm"
                  variant="info"
                  className="text-sm fw-bold me-2"
                  onClick={() => downloadHandler('proofOfRegistration')}
                >
                  <FaPrint className="me-2" />
                  PRINT PROOF OF REGISTRATION
                </Button>

                <Button
                  size="sm"
                  variant="info"
                  className="text-sm fw-bold"
                  onClick={() => downloadHandler('examPermit')}
                >
                  <FaPrint className="me-2" />
                  PRINT EXAM PERMIT
                </Button>
              </div>
            )}
        </Card.Header>
        <Card.Body className="p-0">
          <CourseUnitsTable
            courseUnits={orderBy(registrationHistory?.course_units, [
              'course_unit_code',
            ])}
            keyField="course_unit_id"
          />
        </Card.Body>
      </Card>
    </>
  );
}

SingleRegistrationHistory.defaultProps = {
  registrationHistory: {},
};

SingleRegistrationHistory.propTypes = {
  registrationHistory: PropTypes.oneOfType([object]),
  setSelectedHistory: PropTypes.func.isRequired,
};

export default SingleRegistrationHistory;
