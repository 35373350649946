const settings = {
  setSelectedMenu: (e) => ({
    type: 'SET_SELECTED_MENU',
    payload: e,
  }),

  switchSideMenuTab: (e) => ({
    type: 'SWITCH_SIDE_MENU_TAB',
    payload: e,
  }),

  setShowModal: (e) => ({
    type: 'SET_SHOW_MODAL',
    payload: e,
  }),

  setIsToggled: (e) => ({
    type: 'SET_IS_TOGGLED',
    payload: e,
  }),

  setRegistrationCardType: (e) => ({
    type: 'SET_REGISTRATION_CARD_TYPE',
    payload: e,
  }),

  setShowAllocateModal: (e) => ({
    type: 'SET_SHOW_ALLOCATE_MODAL',
    payload: e,
  }),

  setShowPRNModal: (e) => ({
    type: 'SET_SHOW_PRN_MODAL',
    payload: e,
  }),

  showOffsetInvoiceModal: (show, invoiceData) => ({
    type: 'SHOW_OFFSET_INVOICE_MODAL',
    show,
    invoiceData,
  }),

  setNavigation: (data) => ({
    type: 'SET_APP_NAVIGATION',
    data,
  }),
};

export default settings;
