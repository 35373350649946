const enrollment = {
  enrollment: {
    gettingCurrentEvents: false,
    currentEvents: [],
    currentEventsError: {},

    gettingEnrollmentHistory: false,
    enrollmentHistoryError: {},
    enrollmentHistories: [],

    enrolling: false,
    enrollmentError: {},
    enrollmentSuccess: [],

    gettingMigratedEnrollments: false,
    migratedEnrollments: {},
    migratedEnrollmentError: {},

    migratedRecordsError: {},
    migratedRecords: [],
    gettingMigratedRecords: false,
  },
};

export default enrollment;
