const service = {
  GET_PENDING_APPLICATIONS_REQUEST: 'GET_PENDING_APPLICATIONS_REQUEST',
  GET_PENDING_APPLICATIONS_SUCCESS: 'GET_PENDING_APPLICATIONS_SUCCESS',
  GET_PENDING_APPLICATIONS_ERROR: 'GET_PENDING_APPLICATIONS_ERROR',

  DELETE_PENDING_APPLICATIONS_REQUEST: 'DELETE_PENDING_APPLICATIONS_REQUEST',
  DELETE_PENDING_APPLICATIONS_SUCCESS: 'DELETE_PENDING_APPLICATIONS_SUCCESS',
  DELETE_PENDING_APPLICATIONS_ERROR: 'DELETE_PENDING_APPLICATIONS_ERROR',

  GET_SERVICE_EVENT_REQUEST: 'GET_SERVICE_EVENT_REQUEST',
  GET_SERVICE_EVENT_SUCCESS: 'GET_SERVICE_EVENT_SUCCESS',
  GET_SERVICE_EVENT_ERROR: 'GET_SERVICE_EVENT_ERROR',

  SUBMIT_APPLICATION_REQUEST: 'SUBMIT_APPLICATION_REQUEST',
  SUBMIT_APPLICATION_SUCCESS: 'SUBMIT_APPLICATION_SUCCESS',
  SUBMIT_APPLICATION_ERROR: 'SUBMIT_APPLICATION_ERROR',

  UPDATE_APPLICATION_REQUEST: 'UPDATE_APPLICATION_REQUEST',
  UPDATE_APPLICATION_SUCCESS: 'UPDATE_APPLICATION_SUCCESS',
  UPDATE_APPLICATION_ERROR: 'UPDATE_APPLICATION_ERROR',

  GET_PROGRAMMES_REQUEST: 'GET_PROGRAMMES_REQUEST',
  GET_PROGRAMMES_SUCCESS: 'GET_PROGRAMMES_SUCCESS',
  GET_PROGRAMMES_ERROR: 'GET_PROGRAMMES_ERROR',

  GENERATE_SERVICE_PRN_REQUEST: 'GENERATE_SERVICE_PRN_REQUEST',
  GENERATE_SERVICE_PRN_SUCCESS: 'GENERATE_SERVICE_PRN_SUCCESS',
  GENERATE_SERVICE_PRN_ERROR: 'GENERATE_SERVICE_PRN_ERROR',

  DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_REQUEST:
    'DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_REQUEST',
  DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_SUCCESS:
    'DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_SUCCESS',
  DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_ERROR:
    'DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_ERROR',

  GET_SERVICE_HISTORY_REQUEST: 'GET_SERVICE_HISTORY_REQUEST',
  GET_SERVICE_HISTORY_SUCCESS: 'GET_SERVICE_HISTORY_SUCCESS',
  GET_SERVICE_HISTORY_ERROR: 'GET_SERVICE_HISTORY_ERROR',

  submitApplication: (data, category, studentProgrammeId) => ({
    type: service.SUBMIT_APPLICATION_REQUEST,
    data,
    category,
    studentProgrammeId,
  }),

  deletePendingService: (
    category,
    serviceId,
    studentProgrammeId,
    serviceType
  ) => ({
    type: service.DELETE_PENDING_APPLICATIONS_REQUEST,
    category,
    studentProgrammeId,
    serviceType,
    serviceId,
  }),

  updateApplication: (data, category, studentProgrammeId, applicationId) => ({
    type: service.UPDATE_APPLICATION_REQUEST,
    data,
    category,
    studentProgrammeId,
    applicationId,
  }),

  getServiceEvents: (studentProgrammeId, serviceType) => ({
    type: service.GET_SERVICE_EVENT_REQUEST,
    studentProgrammeId,
    serviceType,
  }),

  getPendingApplications: (category, studentProgrammeId, serviceType) => ({
    type: service.GET_PENDING_APPLICATIONS_REQUEST,
    category,
    studentProgrammeId,
    serviceType,
  }),

  generateServicePRN: (category, id, studentProgrammeId, serviceType) => ({
    type: service.GENERATE_SERVICE_PRN_REQUEST,
    category,
    id,
    serviceType,
    studentProgrammeId,
  }),

  getServiceHistory: (category) => ({
    type: service.GET_SERVICE_HISTORY_REQUEST,
    category,
  }),

  getProgrammes: () => ({
    type: service.GET_PROGRAMMES_REQUEST,
  }),

  downloadServiceAcceptanceLetter: (category, serviceId) => ({
    type: service.DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_REQUEST,
    category,
    serviceId,
  }),
};

export default service;
