import initialState from '../../initialState';

function settings(state = initialState.setting, action) {
  switch (action.type) {
    case 'SET_SELECTED_MENU':
      return {
        ...state,
        selectedMenu: action.payload,
      };

    case 'SWITCH_SIDE_MENU_TAB':
      return {
        ...state,
        sideMenuTab: action.payload,
      };

    case 'SET_SHOW_MODAL':
      return {
        ...state,
        showModal: action.payload,
      };

    case 'SET_IS_TOGGLED':
      return {
        ...state,
        isToggled: action.payload,
      };

    case 'SET_REGISTRATION_CARD_TYPE':
      return {
        ...state,
        registrationCardType: action.payload,
      };

    case 'SET_SHOW_ALLOCATE_MODAL':
      return {
        ...state,
        showAllocateModal: action.payload,
      };

    case 'SET_SHOW_PRN_MODAL':
      return {
        ...state,
        showPRNModal: action.payload,
      };

    case 'SHOW_OFFSET_INVOICE_MODAL':
      return {
        ...state,
        showOffsetInvoiceModal: {
          show: action.show,
          invoiceData: action.invoiceData,
        },
      };
    case 'SET_APP_NAVIGATION':
      return {
        ...state,
        appNavigation: action.data,
      };

    default:
      return state;
  }
}

export default settings;
