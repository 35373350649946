import React from 'react';
import { Card } from 'react-bootstrap';
import { Breadcrumb } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { find, toUpper } from 'lodash';
import { FaHome } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { settingActions } from '../../config/actions';
import MenuList from '../../config/routes/MenuList';

function AddressTab() {
  const dispatch = useDispatch();
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const isMobileDevice = useMediaQuery({ maxWidth: 767 });
  const routes = Object.values(appNavigation).filter((route) => route !== null);

  const setSelectedMenu = (content) => {
    dispatch(settingActions.setSelectedMenu(content));
    if (isMobileDevice) dispatch(settingActions.setIsToggled(false));
  };

  const handleClick = () => {
    const findMenu = find(
      Object.keys(MenuList),
      (menuKey) => MenuList[menuKey].action === 'self-enrollment'
    );

    setSelectedMenu(findMenu ? MenuList[findMenu] : {});
  };

  return (
    <Card className="border-0 bg-transparent mt-1 py-2 px-4">
      <Breadcrumb separator=">" className="py-1">
        {selectedMenu.action !== 'self-enrollment' && (
          <Breadcrumb.Item className="fw-bold text-xs">
            <FaHome onClick={handleClick} className="mb-1" />
          </Breadcrumb.Item>
        )}
        {routes.map((route) => (
          <Breadcrumb.Item key={route} className="fw-bold text-xs">
            {toUpper(route)}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Card>
  );
}

export default AddressTab;
