import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  CustomModal,
  SelectInput,
  SubmitButton,
} from '../../../components/common';
import { appActions, paymentActions } from '../../../config/actions';
import { formatMetadata } from '../../../helpers/dataFormatter';

function GenerateInvoiceForm({ showModal, setShowModal }) {
  const { metadata } = useSelector((state) => state.app);
  const { generatingInvoice } = useSelector((state) => state.myTransaction);
  const { authUser } = useSelector((state) => state.auth);
  const { handleSubmit, register, errors } = useForm();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(showModal);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(metadata)) {
      dispatch(appActions.getMetadata());
    } else {
      setAcademicYearOptions(
        formatMetadata(metadata, 'ACADEMIC YEARS', 'id', 'desc').filter(
          (year) => year.label.includes('2021/2022')
        )
      );
    }
  }, [metadata]);

  const onClickProceed = () => {
    setShowDisclaimer(false);
    setShowForm(true);
  };

  const onSubmit = (data) => {
    const studentProgrammeId = authUser.academic_records?.find(
      (record) => record.is_current_programme
    );
    dispatch(
      paymentActions.generateGraduationInvoice({
        ...data,
        student_programme_id: studentProgrammeId.id,
      })
    );
  };

  const disclaimerMessage =
    'DISCLAIMER: THIS FEATURE IS STRICTLY FOR STUDENTS (FINALISTS)' +
    '\n' +
    'WHO HAVE COMPLETED STUDIES, AND THEIR NAMES APPEAR ON THE GRADUATION LIST';

  return (
    <>
      {showDisclaimer && (
        <CustomModal
          backdrop="static"
          keyboard={false}
          show={showDisclaimer}
          onCloseModal={() => setShowModal(false)}
          size="xl"
          title="DISCLAIMER !!!"
        >
          <ModalBody className="text-center">
            <AlertMessage
              icon={
                <FaExclamationTriangle
                  size="2rem"
                  className="me-4 text-center"
                />
              }
              message={disclaimerMessage}
              variant="warning"
              className="text-center p-5 fs-4 bg-warning"
            >
              <p className="fs-3 font500 mt-4">USE IT AT YOUR OWN RISK!</p>
            </AlertMessage>
          </ModalBody>
          <ModalFooter className="py-2 bg-light border-top">
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShowModal(false);
              }}
              className="text-uppercase"
            >
              CANCEL
            </Button>
            <Button
              variant="success"
              size="sm"
              onClick={onClickProceed}
              className="text-uppercase"
            >
              PROCEED
            </Button>
          </ModalFooter>
        </CustomModal>
      )}
      {showForm && (
        <Card body>
          <Card.Header className="mb-4">
            GENERATE GRADUATION INVOICE
          </Card.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center align-items-center">
              <Col md={6}>
                <SelectInput
                  name="grad_academic_year_id"
                  placeholder="Select Your Graduation Academic Year"
                  register={register({
                    required: 'Select A Graduate academic year',
                  })}
                  requiredField
                  selectOptions={academicYearOptions}
                  error={get(errors, 'grad_academic_year_id.message')}
                  className="text-dark text-lg"
                />
              </Col>
              <Col md={2}>
                <SubmitButton
                  className="text-uppercase text-sm font600 "
                  loading={generatingInvoice}
                  disabled={generatingInvoice}
                  loadingText="Generating..."
                  text="Generate"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
}

GenerateInvoiceForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default GenerateInvoiceForm;
